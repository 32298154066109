import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoSm from "../../../assets/icons/logo-1.jpg";
import { ReadCountriesService } from "../../../services/general";
import { GenerateOTPResetPasswordService } from "../../../services/user";
import "../auth-common.css";

const ForgotPassword = () => {
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        mobile: "",
        countryCode: ""
    });
    const [formErrors, setFormErrors] = useState({
        mobile: "",
        countryCode: ""
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const countryCodeError = formData.countryCode ? "" : "Country Code cannot be Blank";
        const mobileError = formData.mobile ? "" : "Mobile cannot be Blank";
        setFormErrors({
            countryCode: countryCodeError,
            mobile: mobileError
        });

        if (!countryCodeError && !mobileError) {
            setLoading(true);
            let mobile = '+' + formData.countryCode + formData.mobile;
            let temp_generate_otp = await GenerateOTPResetPasswordService(mobile);
            if (temp_generate_otp.success) {
                navigate("/forgot-password/otp", { state: { mobile: mobile } });
            } else {
                if (temp_generate_otp.message) {
                    setFormErrors((prev) => ({ ...prev, mobile: temp_generate_otp.message }));
                } else {
                    setFormErrors((prev) => ({ ...prev, mobile: temp_generate_otp.detail }));
                }
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            let temp_countries = await ReadCountriesService();
            if (temp_countries) {
                setCountries(temp_countries);
            }
            setLoading(false);
        })();
    }, []);

    return (
        <div className="forgot-pswd-container auth-container-main">
            <div className="wrapper-side form-container">
                <div className="form-container">
                    <div className="logo-wrapper">
                        <img src={logoSm} alt="" />
                    </div>
                    <h1 className="auth-title" >Reset Your Password</h1>
                    <form onSubmit={handleSubmit} className="auth-form signup">
                        <div className="form-group-dx">
                            <label htmlFor="mobile">Please enter your valid mobile to get OTP.</label>
                            <div className="form-group-country-mobile">
                                <div className="form-group-country">
                                    <select id="countryCode" name="countryCode" required onChange={handleInputChange}>
                                        <option>Select Country</option>
                                        {countries.map((country) => {
                                            return (
                                                <option key={country.id} value={country.phone_code}>
                                                    +{country.phone_code} - {country.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="form-group-mobile">
                                    <input
                                        type="tel"
                                        id="mobile"
                                        name="mobile"
                                        value={formData.mobile}
                                        placeholder="Enter mobile number"
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {formErrors.countryCode && (
                            <p className="dx-lib input-error">{formErrors.countryCode}</p>
                        )}
                        {formErrors.mobile && (
                            <p className="dx-lib input-error">{formErrors.mobile}</p>
                        )}
                        <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                            {loading ? "Generating OTP..." : "Generate OTP"}
                        </button>
                    </form>
                    <p className="account-exist-check">
                        Remember password? <Link to="/">Login</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;